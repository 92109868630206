import React from 'react'
import DefaultLayout from '../layouts/default'
import TeamModal from '../components/team-modal/team'
export default function TeamPage ()  {
  return (
    <DefaultLayout>
        <TeamModal></TeamModal>
    </DefaultLayout>
  )
}

