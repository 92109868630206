import React from 'react';

import SignUp from '../components/login-modal/signUp';
import DefaultLayout from '../layouts/default';

export default function SingUpPage() {
  return (
<DefaultLayout>
      <SignUp></SignUp>
    </DefaultLayout>
    
  );
}
